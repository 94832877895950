import React from "react"
import Color from "color"

import remoteAssetUrl from '../../lib/remote-asset-url'

import Button from '../button'

import styles from './component.module.less'

class Sidebar extends React.Component {

  baseColor;
  color;
  dark = false;

  constructor(props) {
    super(props);

    this.baseColor = Color(props.baseColor || '#E8C5A1');
    this.color = this.baseColor.lighten(0.11);
  }

  containerClasses() {
    let classes = [styles.sidebar];

    if (!this.props.isTop) {
      classes.push(styles.notTop);
    }

    if (this.baseColor.luminosity() < 0.5) {
      classes.push(styles.dark);
    }

    return classes.join(' ');
  }

  render() {
    return (
        <aside
            data-datocms-noindex={true}
            className={this.containerClasses()}
            style={{
              background: this.baseColor.hex(),
            }}
        >
          <div
              className={styles.inner}
              style={{
                background: this.color.hex(),
              }}
          >

            {this.props.items &&
              <div className={styles.toc}>
                <strong>
                  {this.props.tocLabel}
                </strong>
                <ol>
                  {this.props.items.map((item, i) => (
                      <li key={i} className={item.active ? styles.active : ''}>
                        <a href={'#' + item.id}>{item.name}</a>
                      </li>
                  ))}
                </ol>
              </div>
            }

            {this.props.contact &&
              <div className={styles.contact}>
                <div className={styles.person}>
                  <div
                      className={`${styles.avatar}${!this.props.contact.image ? ' ' + styles.empty : ''}`}
                      style={{
                        backgroundColor: this.props.contact.image ? this.props.contact.image.colors[0].hex : null,
                        backgroundImage: this.props.contact.image ? 'url(' + remoteAssetUrl(this.props.contact.image.fluid.src) + ')' : null,
                      }}
                  >
                    {this.props.contact.name[0]}
                  </div>

                  <div className={styles.desc}>
                    <span>{this.props.contact.name}</span>
                    <span>
                      {this.props.contactLabel}
                    </span>
                  </div>
                </div>

                {this.props.contactPath &&
                  <Button theme="block" href={this.props.contactPath}>
                    {this.props.contactButtonLabel}
                  </Button>
                }
              </div>
            }
          </div>
        </aside>
    );
  }
}

export default Sidebar;
