import React, {useState} from "react"
import {graphql} from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import Color from "color"

import id from "../lib/id";

import Layout from '../layouts/default'

import ContentElements from '../components/content-elements'

import Container from "../components/content-container";
import Header from "../components/header";
import Sidebar from "../components/product-sidebar";
import Text from "../components/text";
import TextDecorator from "../components/text-decorator";

import MediaText from "../content-elements/media-text";

import styles from "./single-product/component.module.less";

export default ({data, pageContext}) => {

  const locale = pageContext.locale || 'de';

  const [headerVisible, setHeaderVisible] = useState(true);

  const config = data.allDatoCmsConfig.nodes[0];

  const product = data.datoCmsProdukt;
  const productColor = Color(product.color.hex);

  if (product.detailImage && product.detailImage.fluid && !product.detailImage.fluid.src.includes('&fp-z=')) {
    product.detailImage.fluid.src += '&fp-z=1.3';
  }

  // anchors

  const introAnchor = pageContext.templateStrings.productPageSidebarIntroLabel;

  let anchorItems = product.content.map(ce => ce.anchorName).filter(a => a !== undefined && a.length > 0);
  anchorItems.unshift(introAnchor);
  anchorItems = anchorItems.map((a, i) => {
    return {
      id: id(a),
      name: a,
      active: i === 0,
    }
  });

  const [anchors, setAnchors] = useState(anchorItems);

  // element visibility

  const onHeaderVisibilityChange = (visible) => {
    setHeaderVisible(visible);

    const items = anchorItems.map((a, i) => {
      a.active = i === (visible ? 0 : 1);
      return a;
    });
    setAnchors(items);
  };

  const onElementVisibilityChange = (visibleId, visible) => {
    if (!visible) return;

    const index = anchorItems.find(a => a.id === visibleId);
    if (!index) return;

    const items = anchorItems.map(a => {
      a.active = a.id === visibleId;
      return a;
    });
    setAnchors(items);
  };

  // render

  return (
      <Layout
          className={styles.productPage}
          locale={locale}
          localeLinks={pageContext.localeLinks}
          seo={product.seoMetaTags}
      >
        {{
          header: (
              <Header
                  sticky={false}
                  className={styles.header}
                  locale={locale}
                  localeLinks={pageContext.localeLinks}
              />
          ),
          content: (
              <>
                <VisibilitySensor
                    resizeCheck={false}
                    partialVisibility={true}
                    offset={{top: 260, bottom: 260}}
                    onChange={onHeaderVisibilityChange}
                >
                  <>
                    {product.detailImage &&
                      <MediaText
                          id={id(introAnchor)}
                          className={styles.topic}
                          header={true}
                          dark={productColor.luminosity() < 0.5}
                          style={{
                            background: productColor.hex() + ' linear-gradient(270deg, ' + productColor.hex() + ' 0%, rgba(0,0,0,0) 100%)',
                          }}
                          image={product.detailImage}
                          imageBorder={false}
                          emphasizeMedia={true}
                          title={product.category}
                          headline={product.headline || product.name}
                          headlineType="h1"
                          htmlText={product.introduction}
                          childPosition="before"
                      >
                        <TextDecorator className={styles.decorator} widthOffset={-130} baseColor={productColor}>{product.name}</TextDecorator>
                      </MediaText>
                    }
                    {!product.detailImage &&
                        <Container
                            id={id(introAnchor)}
                            className={styles.topicTxt}
                            style={{
                              background: productColor.hex() + ' linear-gradient(270deg, ' + productColor.hex() + ' 0%, rgba(0,0,0,0) 100%)',
                            }}
                        >
                          <TextDecorator className={styles.decorator} widthOffset={-130} baseColor={productColor}>{product.name}</TextDecorator>
                          <Text
                              title={product.category}
                              headline={product.name}
                              headlineType="h1"
                              htmlText={product.introduction}
                              dark={productColor.luminosity() < 0.5}
                          />
                        </Container>
                    }
                  </>
                </VisibilitySensor>

                <Sidebar
                    tocLabel={pageContext.templateStrings.productPageSidebarTocLabel}
                    contactLabel={pageContext.templateStrings.productPageSidebarContactLabel}
                    contactButtonLabel={pageContext.templateStrings.productPageSidebarContactButtonLabel}
                    isTop={headerVisible}
                    baseColor={productColor}
                    items={anchors}
                    contact={product.contact || config.contact}
                    contactPath={'/' + locale + '/' + config.pageContact.slug + '/'}
                />

                <ContentElements
                    locale={locale}
                    items={product.content}
                    config={config}
                    onVisibilityChange={onElementVisibilityChange}
                    classNames={{
                      'DatoCmsProductmediaText': (ce) => (ce.alignment || '').toLowerCase() !== process.env.GATSBY_CONTENT_ALIGNMENT_LEFT ? styles.mediaTextReverse : styles.mediaText,
                      'DatoCmsMediaText': (ce) => ce.imageAside ? styles.mediaTextAside : '',
                      'DatoCmsImage': () => styles.img,
                    }}
                    mutators={{
                      'DatoCmsProductlist': (ce) => {ce.full = false; return ce;},
                    }}
                />
              </>
          ),
        }}
      </Layout>
  );
}

export const query = graphql`
    query($locale: String!, $id: String!) {
  
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                ...config_data
            }
        }
  
        datoCmsProdukt(id: {eq: $id }) {
            ...product_content
            seoMetaTags {
                tags
            }
            id
            name
            category
            headline
            introduction
            slug
            color {
                hex
            }
            detailImage {
                alt
                fluid(imgixParams: {w: "1020", h: "1020", auto: "compress", dpr: 1.5, q: 75, fit: "crop", crop: "focalpoint"}) {
                    src
                }
            }
            contact {
                ...contact_minimal
            }
        }

    }
`
